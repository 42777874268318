

  .vibrant-gradient-background {
    background: linear-gradient(135deg, #a0ade7 0%, #5351c4 100%);
  }

  ion-card {
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  ion-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4a5568;
  }

  ion-card-content {
    font-size: 1.1rem;
    color: #718096;
  }

  .rounded-full {
    border-radius: 9999px;
  }

  .bg-primary {
    background-color: #4299e1;
  }