.pronunciationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: center;
    padding: 16px;
}

.textareaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.progressBar {
    height: 10px;
}

.textareaContainer ion-textarea {
    margin-bottom: 16px;
}

@keyframes successGlow {
    0% {
        box-shadow: 0 0 2px #ffffff;
    }
    50% {
        box-shadow: 0 0 15px #4caf50;
    }
    100% {
        box-shadow: 0 0 2px #ffffff;
    }
}

.successAnimation {
    animation: successGlow 0.6s ease-in-out;
}
