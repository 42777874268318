.word-feedback-popover {
  --max-width: 300px;
  --width: 100%;
  --background: #f3f4f6;
  --border-radius: 8px;
  --box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.word-feedback-popover .popover-content {
  max-height: 400px;
  overflow-y: auto;
}