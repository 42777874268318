@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  font-family: "Fredoka", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --ion-color-primary: #3473d8;
  --ion-color-primary-rgb: 242, 128, 10;
  --ion-color-primary-contrast: #2c2c2c;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #26549e;
  --ion-color-primary-tint: #3473d9;

  --ion-color-secondary: #d89243;
  --ion-color-secondary-rgb: 239, 169, 77;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d89243;
  --ion-color-secondary-tint: #da9444;

  --ion-color-tertiary: #49a078; /* Updated to complement primary and secondary */
  --ion-color-tertiary-rgb: 73, 160, 120;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #3d8965;
  --ion-color-tertiary-tint: #5bb88d;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #f0f4f9; /* Softened to provide more contrast */
  --ion-color-light-rgb: 240, 244, 249;
  --ion-color-light-contrast: #2c2c2c;
  --ion-color-light-contrast-rgb: 44, 44, 44;
  --ion-color-light-shade: #d9dee4;
  --ion-color-light-tint: #f3f7fb;

  --ion-color-medium: #8c7a6f; /* More neutral tone to complement other colors */
  --ion-color-medium-rgb: 140, 122, 111;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #7a6a60;
  --ion-color-medium-tint: #a08e82;

  --ion-color-dark: #1f2e46; /* Darker, deeper shade for better balance */
  --ion-color-dark-rgb: 31, 46, 70;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #19263a;
  --ion-color-dark-tint: #2a3a56;
}

/* For Webkit browsers (Chrome, Safari) */
&::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background: #ffffff;
}
&::-webkit-scrollbar-track {
  background: #2c2c2c;
}

&::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #26549e, #3473d8);
}

&::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #3473d9, #3473d8);
}

&::-webkit-scrollbar-thumb:active {
  background: linear-gradient(180deg, #26549e, #3473d8);
}
.inner-scroll {
  scrollbar-width: thin;
}
